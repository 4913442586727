import React from "react";
import NavLogoItem from "./nav/NavLogoItem";
import NavDonateItem from "./nav/NavDonateItem";
import NavMenu from "./nav/NavMenu";

const Header = () => {
  return (
    <header className="bg-theme-color-header-background border-b border-theme-color-header-border h-theme-height-header fixed top-0 w-full z-10">
      <nav className="flex h-full items-center mx-auto max-w-theme-width-max-content px-2 py-2">
        <ul className="flex-1 flex items-center justify-between xl:justify-start xl:gap-8">
          <NavLogoItem />
          <li className="xl:flex-1">
            <div className="flex items-center justify-between gap-2 xl:flex-row-reverse">
              <NavDonateItem />
              <NavMenu />
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
