import { useState, useEffect } from "react";

export function useCookie(name) {
  const [cookie, setCookie] = useState(null);

  useEffect(() => {
    const getCookie = () => {
      if (typeof document !== "undefined") {
        const cookieString = document.cookie
          .split("; ")
          .find((row) => row.startsWith(name));
        return cookieString ? cookieString.split("=")[1] : null;
      }
      return null;
    };

    setCookie(getCookie());
  }, [name]);

  const updateCookie = (value, days) => {
    if (typeof document !== "undefined") {
      const expires = days
        ? `expires=${new Date(
            Date.now() + days * 24 * 60 * 60 * 1000
          ).toUTCString()}`
        : "";
      document.cookie = `${name}=${value}; ${expires}; path=/`;
      setCookie(value);
    }
  };

  return [cookie, updateCookie];
}
