import React from "react";
import NavItemOut from "./NavItemOut";

const NavGroup = ({ title, items }) => {
  return (
    <li className="flex flex-col gap-3">
      <h2 className="font-bold text-xl text-center lg:text-2xl 2xl:text-3xl md:text-left">
        {title}
      </h2>
      <ul className="flex flex-col items-center justify-center md:items-start">
        {items.map((item, key) => {
          const { href, icon, iconStyle, text } = item;
          return (
            <NavItemOut
              key={key}
              href={href}
              icon={icon}
              iconStyle={iconStyle}
              text={text}
            />
          );
        })}
      </ul>
    </li>
  );
};

export default NavGroup;
