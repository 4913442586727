import React, { useState } from "react";
import { useCookie } from "../../hooks/useCookie";
import { useTranslation, withTranslation } from "gatsby-plugin-react-i18next";
import Icon from "../icon/Icon";

const CookieConsent = () => {
  const { t } = useTranslation();
  const pageTranslation = t("sections.cookies", { returnObjects: true });

  const [fadeIn, setFadeIn] = useState(false);

  const [consent, setConsent] = useCookie("cookie_consent");

  const handleConsent = () => {
    setConsent(true, 30); // Set the consent cookie for 30 days
  };

  setTimeout(() => {
    setFadeIn(true);
  }, 5000);
  return (
    <>
      {consent ? null : (
        <div className="bottom-0 fixed w-full md:w-fit md:right-0">
          <div
            className={`duration-500 ease p-4 w-full max-w-md ${
              fadeIn ? "translate-y-0" : "translate-y-full"
            }`}
          >
            <div className="bg-white border flex flex-col gap-4 p-4 rounded-lg">
              <div className="flex gap-4 items-center">
                <div className="bg-theme-color-brand-primary h-12 flex items-center justify-center rounded-full text-3xl text-white w-12">
                  <Icon icon={"cookie-bite"} iconStyle="solid" />
                </div>
                <h1 className="font-bold text-xl">{pageTranslation.heading}</h1>
              </div>
              <div className="flex flex-col gap-4">
                <p>{pageTranslation.paragraphs[0]}</p>
                <p>{pageTranslation.paragraphs[1]}</p>
              </div>
              <button
                className="bg-theme-color-brand-primary font-bold mt-2 p-4 rounded-lg text-white w-full"
                onClick={handleConsent}
              >
                {pageTranslation.understood}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(CookieConsent);
