import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Icon from "../../icon/Icon";

const NavMenuItem = ({ to, icon, title, onClick }) => {
  return (
    <li className="border hover:bg-theme-color-brand-primary hover:text-theme-color-brand-primary-contrast rounded">
      <Link
        to={to}
        onClick={onClick}
        className="flex gap-4 items-center p-4 sm:max-xl:flex-col sm:max-xl:h-40 sm:max-xl:justify-center lg:px-3 lg:py-1.5"
      >
        <div className="text-lg">
          <Icon icon={icon} />
        </div>
        <h1 className="font-medium sm:max-xl:text-lg">{title}</h1>
      </Link>
    </li>
  );
};

export default NavMenuItem;
