import React, { useState } from "react";
import NavMenuItem from "./NavMenuItem";
import { useTranslation, withTranslation } from "gatsby-plugin-react-i18next";

const NavMenu = () => {
  const { t } = useTranslation();
  const pageTranslation = t("header", { returnObjects: true });
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <button
        onClick={() => setMenuOpen(!isMenuOpen)}
        aria-label="Menu Button"
        className="aspect-square h-11 w-11 xl:hidden"
      >
        <div className="aspect-square border flex items-center justify-center h-full rounded w-full">
          <div className="flex flex-col gap-1">
            <div
              className={`bg-black duration-300 h-1 w-6 ${
                isMenuOpen ? "rotate-45 translate-y-2" : "rotate-0"
              }`}
            ></div>
            <div
              className={`bg-black duration-300 h-1 w-6 ${
                isMenuOpen ? "opacity-0" : "opacity-100"
              }`}
            ></div>
            <div
              className={`bg-black duration-300 h-1 w-6 ${
                isMenuOpen ? "-rotate-45 -translate-y-2" : "rotate-0"
              }`}
            ></div>
          </div>
        </div>
      </button>
      <div
        className={`max-xl:absolute bg-white duration-300 max-xl:h-theme-height-dvh-full max-xl:inset-x-0 max-xl:top-full max-xl:translate-y-px ${
          isMenuOpen ? "max-xl:translate-x-0" : "max-xl:translate-x-full"
        }`}
      >
        <ul className="flex flex-col gap-3 max-xl:p-4 sm:max-xl:grid sm:max-xl:grid-cols-3 xl:flex-row">
          <NavMenuItem
            to={"/"}
            icon={"house"}
            title={pageTranslation.home}
            onClick={() => setMenuOpen(false)}
          />
          <NavMenuItem
            to={"/projekt"}
            icon={"folder-open"}
            title={pageTranslation.project}
            onClick={() => setMenuOpen(false)}
          />
          <NavMenuItem
            to={"/plan"}
            icon={"diagram-subtask"}
            title={pageTranslation.plan}
            onClick={() => setMenuOpen(false)}
          />
          <NavMenuItem
            to={"/galerie"}
            icon={"images"}
            title={pageTranslation.gallery}
            onClick={() => setMenuOpen(false)}
          />
          <NavMenuItem
            to={"/sprache"}
            icon={"language"}
            title={pageTranslation.language}
            onClick={() => setMenuOpen(false)}
          />
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(NavMenu);
