import React from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import Icon from "../../icon/Icon";

const NavDonateItem = () => {
  const { t } = useTranslation();
  const pageTranslation = t("header", { returnObjects: true });
  return (
    <Link
      className="bg-theme-color-brand-primary flex items-center gap-2 text-theme-color-brand-primary-contrast px-3 py-2 rounded"
      to={"/spenden"}
    >
      <div>
        <Icon icon={"hand-holding-heart"} iconStyle="solid" />
      </div>
      <span className="font-bold">
        <span>{pageTranslation.donate.short}</span>
        <span className="max-md:hidden ml-1">
          {pageTranslation.donate.long}
        </span>
      </span>
    </Link>
  );
};

export default NavDonateItem;
