import React from "react";
import Header from "../header/Header";
import Main from "../main/Main";
import Footer from "../footer/Footer";
import CookieConsent from "../cookie/CookieConsent";

const LayoutHeaderMainFooter = ({ children }) => {
  return (
    <div className="max-md:overflow-x-hidden">
      <Header />
      <Main>{children}</Main>
      <Footer />
      <CookieConsent />
    </div>
  );
};

export default LayoutHeaderMainFooter;
