import React from "react";
import Icon from "../../icon/Icon";

const NavItemOut = ({ href, icon, iconStyle = "solid", text }) => {
  return (
    <li className="w-fit">
      <a href={href} className="block py-1 px-2">
        <div className="flex gap-2 items-center">
          <div className="h-8 flex items-center justify-center text-xl w-8">
            <Icon icon={icon} iconStyle={iconStyle} />
          </div>
          {text && (
            <span className="font-medium leading-5 text-base">{text}</span>
          )}
        </div>
      </a>
    </li>
  );
};

export default NavItemOut;
